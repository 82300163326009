@import "../../styles/elements";

.card-container {
	flex: 1;
	height: 310px;
	width: 260px;
	background-color: $color-white;
	border-radius: 2px;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
	position: relative;
	z-index: 0;
	margin: 10px;
	
	&.compact {
	  height: 90;
	  width: 350;
	  margin-inline: 10px;
	  flex-direction: row;
	  align-items: center;
	}
  }
  
  .image-container {
	border-radius: 2px;
	img {
		height: 215px;
		width: 260px;
		border-radius: 2px;
		object-fit: cover;
	}
  }
  
  .card-content {
	padding: 8px;
	justify-content: flex-start;
	align-items: flex-start;
  }
  
  .card-title {
	font-family: "Avenir Next", sans-serif;
	font-size: 14px;
	color: $color-welbeck-blue;
	font-weight: 500;
	padding-bottom: 3px;
  }
  
  .warning-container {
	position: relative;
	top: 10px;
  }
  
  .card-compact-container {
	&.compact {
	  height: 90;
	  width: 350;
	  margin-inline: 10px;
	  background-color: $color-white;
	  border-radius: 2px;
	  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
	  margin-block: 10;
	  position: relative;
	  z-index: -999;
	  flex-direction: row;
	  align-items: center;
	}
  }
  
  .image-compact-container {
	border-radius: 2px;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
  }
  
  .card-compact-image {
	height: 70px;
	width: 70px;
	border-radius: 2px;
  }
  
  .favourite-compact-container {
	position: absolute;
	bottom: 8px;
	right: 10px;
	padding: 8px;
	border-radius: 20px;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .card-compact-content {
	padding: 8px;
  }
  
  .warning-compact-container {
	position: relative;
	top: 90px;
	left: 35px;
  }
  
  .image-booking-container {
	border-radius: 2px;
	justify-content: center;
	align-items: center;
  }
  
  .favourite-button {
	position: absolute;
	bottom: 100px;
	right: 10px;
	cursor: pointer;
	z-index: 1;
  }