@import "../../styles/elements";

$white: red;
$font: Arial, sans-serif;

.main-field-container {
  flex-wrap: wrap;
  display: flex;
}

.main-field {
  border-radius: 3px;
}

.main-field-text {
  color: $color-white;
  padding: 2px 5px;
  font-size: 12px;
  font-family: $primary-font-family;
}

.sub-field-container {
  flex-wrap: wrap;
  margin-top: 8px;
  overflow: hidden;
  height: 24px;
}

.sub-field {
  background-color: $color-white;
  border: 1px solid; 
  border-radius: 3px;
  margin-right: 5px;
}

.sub-field-text {
  padding: 2px 5px;
  font-size: 11px;
  font-weight: 500;
  font-family: $primary-font-family;
}
