@import "../../styles/elements";

.favourite-container {
    cursor: pointer;
	display: flex;
    padding: 8px;
    border-radius: 50px;
	justify-content: center;
	align-items: center;
    background-color: $color-white;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
}