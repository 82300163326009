@import "../../../styles/elements";

.header {
  z-index: 9999;
  &.floating-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  .header__inner {
    background-color: $header-bg-color;
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    color: $color-welbeck-purple;
    padding: 0 16px;
    height: 60px;
    border-bottom: 1px solid $color-welbeck-grey;
		font-family: Avenir Next;
    align-items: center;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    .header__title {
      margin: 0;
      font-weight: 600;
      font-size: 16px;
      color: inherit;
      text-transform: capitalize;
    }
    .backlink {
      .icon {
        font-size: 32px;
      }
    }
    .ant-row-flex {
      .ant-col {
        &:first-child {
          > :first-child {
            margin-right: 10px;
          }
        }
      }
    }
  }
  a {
    color: currentColor;
  }

  .icon {
    fill: currentColor;
  }

  .icon-menu {
    font-size: 48px;
    margin-right: 6px;
  }

  .icon-bell {
    font-size: 19px;
  }

  .icon-new-order {
    font-size: 16px;
  }
  .toolbar-button {
    background: transparent;
    border: none;
    &:hover {
      background: transparent;
      border: none;
    }
  }

  .logo {
    width: 40px;
    margin-right: 20px;
  }

  .input-container {
    display: flex;
    align-items: center;
    input {
      border: 1px solid $color-welbeck-grey; 
      border-radius: 4px;
      height: 40px;
      padding: 8px;
      font-size: 14px;
      width: 100%;
      padding-left: 40px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.3s;
    }
  }

  .custom-input {
    width: 100%;
    color: $primary-color;
    font-family: Avenir Next;
    &::placeholder {
      color: $color-welbeck-grey;
    }
  }

  .change-location-link {
    cursor: pointer;
    font-size: 16px;
    text-decoration: underline;
    margin-left: 20px;
    @media screen and (max-width: 65rem) {
      display: none;
    }
  }

  .location-container {
    display: flex;
    justify-content: center;
    align-items: center;
      span {
        color: $primary-color;
        font-size: 18px;
      }
  }

  .location-icon {
    width: 16px;
    margin-right: 5px;
  }

  .basket-container {
    display: flex;
    justify-content: center;
    align-items: center;
      span {
        font-size: 18px;
        font-weight: 600;
      }
  }

  .search-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
  }

  .basket-icon {
    width: 22px;
    margin-right: 5px;
  }

  .basket-title {
    @media screen and (max-width: 85rem) {
      display: none;
    }
  }
}