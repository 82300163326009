@import "../../styles/elements";

.availability-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative;
  min-height: 100vh;
  padding-bottom: 60px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../assets/images/background.png') no-repeat center center fixed;
    background-size: cover;
    opacity: 0.2; 
    z-index: -1; 
  }

  .header {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .logo {
      width: 50px;
      margin-right: 0px;
    }
  }

  .progress-indicator {
    width: 100%;
    margin-bottom: 20px;
  }

  .booking-info {
    font-size: 16px;
    color: #202B5B;
    margin-bottom: 20px;
    margin-left: 10px;
  }

  .availability-content {
    width: 100%;
    max-width: 600px;
    text-align: left;

    .instruction {
      font-size: 16px;
      color: #202B5B;
      margin-bottom: 0px;
      padding: 20px 10px;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid #ccc;    
      background-color: white;
    }
  }

  .next-button {
    font-size: 18px;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 45px;
    border-radius: 0;
    cursor: pointer;

    &:disabled {
      background-color: #d5d6d2;
      color: #a0a0a0;
      cursor: not-allowed;
    }

    @media (max-width: 600px) {
      font-size: 18px;
    }
  }
}
