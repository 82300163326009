@import "../../styles/elements";

.security-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    position: relative;
    min-height: 100vh;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('../../assets/images/background.png') no-repeat center center fixed;
        background-size: cover;
        opacity: 0.2;
        z-index: -1;
    }

    .header {
        display: flex;
        margin-bottom: 20px;

        .logo {
            width: 50px;
            margin-right: 0px;
        }
    }

    .security-form label {
        color: #202B5B;
        text-align: left;
    }
    .security-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-width: 400px;
        text-align: center;
        z-index: 2;
    }

    .security-title {
        font-size: 24px;
        font-weight: bold;
        color: #202B5B;
        margin-bottom: 16px;
        text-align: left;
    }

    .security-description {
        font-size: 16px;
        color: #202B5B;
        margin-bottom: 32px;
        text-align: left;
    }

    .security-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 350px;
        margin-bottom: 20px;

        label {
            margin-bottom: 8px;
            font-weight: 500;
            color: #202B5B;
        }

        .ant-input {
            margin-bottom: 16px;
            height: 50px;
            padding: 10px;
            border-radius: 0;

            &::placeholder {
                font-family: $primary-font-family;
            }
        }
    }

    .next-button {
        font-size: 18px;
        width: 100%;
        max-width: 350px;
        padding-top: 10px;
        padding-bottom: 40px;
        border-radius: 0;
        position: absolute;
        bottom: 20px;
    }

    .next-button:disabled {
        background-color: #d5d6d2;
        color: #a0a0a0;
        cursor: not-allowed;
    }

    .no-hover.ant-btn:not(:disabled):not(.ant-btn-disabled):hover {
        color: white !important;
        border-color: none !important;
        background-color: #202B5B !important;
    }
}

@media only screen and (max-width: 600px) {
    .security-page {
        padding: 10px;
    }

    .security-title {
        font-size: 20px;
    }

    .security-description {
        font-size: 14px;
    }

    .security-form {
        max-width: 100%;
    }

    .next-button {
        font-size: 18px;
        max-width: 100%;
    }
}
