@import "../../../styles/elements";

.modal-container {
	background-color: rgba(0, 0, 0, 0.5);
	justify-content: flex-end;
}
  
.modal-content {
	background-color: white;
	height: 300px;
	border-radius: 2px;
	padding: 10px;
	padding-top: 20px;
}
  
.title {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 10px;
	font-family: Avenir Next;
	color:	$primary-color;
}
  
.location-item {
	color: $primary-color;
	font-family: Avenir Next;
	font-size: 16px;
	margin: 10px;
}
  
.separate-line {
	border: 0.5px solid $color-welbeck-grey; 
}

.location-button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-block: 20px;
}

.location-icon {
	width: 16px;
}
