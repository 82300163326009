@import "../../styles/elements";

.loading-spinner {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	background-color: white;
	position: relative;
	z-index: 2;
  }
  
  .spinner {
	border: 4px solid rgba(0, 0, 0, 0.1);
	border-left-color: #000;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }

.clinician-container {
	flex: 1;
    flex-direction: column;
	padding-left: 20px;
  }

.flatlist-container {
	display: flex;
    flex-direction: row;
    overflow-x: auto; 
}

.service-flatlist-container {
	display: flex;
    flex-direction: row;
    overflow-x: auto; 
	padding-left: 10px;
}

.topic-text {
	font-size: 20px;
	font-weight: 600;
	margin-left: 10px;
}

.speciality-container {
	margin-top: 20px;
}

.lastbook-item {
	display: flex;
	overflow-x: auto;
}

.lastbook-container {
	margin-top: 20px;
}