@import "../../styles/elements";

.payment-method {
    margin-bottom: 20px;
    background-color: white;
    width: 100%;
    padding: 20px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);

    h3 {
        margin-bottom: 20px;
    }

    .apple-pay-button,
    .credit-card-button,
    .insurance-button {
        margin-bottom: 10px;
        font-size: 20px;
        padding-top: 15px;
        padding-bottom: 45px;
        font-weight: 500;
        width: 100%;
        border-radius: 0;
        border: 2px solid #202B5B;
    }

    .credit-card-button.focus,
    .apple-pay-button:focus,
    .insurance-button:focus {
        border: 2px solid #8b008b !important;
        color: #fff !important;
        background-color: #8b008b;
    }

    .credit-card-form,
    .insurance-form {
        margin-top: 10px;
        .ant-input,
        .ant-select {
            margin-bottom: 10px;
            border-radius: 0;

            &::placeholder {
                color: #202B5B !important;
                font-family: $primary-font-family;
            }

            .ant-select-selector {
                border-radius: 0;
                height: 55px;
            }

            .ant-select-selection-placeholder {
                color: #202B5B !important;
                margin-top: 12px;
            }

            .ant-select-arrow {
                color: #202B5B;
                font-size: 18px;
            }

            .ant-select-selection-item {
                font-size: 18px;
                color: #202B5B;
                margin-top: 12px;
            }
        }

        .ant-form-item {
            margin-bottom: 10px;
        }

        .ant-btn-primary {
            padding-top: 15px;
            padding-bottom: 45px;
            font-weight: 500;
            width: 100%;
            font-size: 20px;
            border-radius: 0;
        }
    }

    .expiry-cvv {
        display: flex;
        justify-content: space-between;

        .ant-form-item {
            flex: 1;
        }

        .ant-form-item:first-child {
            margin-right: 10px;
        }
    }
}
