@import "../../styles/elements";

.ant-tabs-nav-wrap {
	padding-left: 30px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: $color-welbeck-purple !important;
	text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs-ink-bar {
	height: 10px;
	background: $color-welbeck-purple !important;
}

.tabsStyle {
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
}
