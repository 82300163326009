.forgot-password-wrap {
	display: flex;
	flex-direction: row;
	display: flex;
	justify-content: center;
	align-items: center;
}

.button-link {
	padding: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	span {
		font-size: 16px;
		text-align: center;
		color: #202B5B;
		text-decoration: underline;
	}
}

.form {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	min-width: 450px;
	h1 {
		font-size: 30px;
		font-weight: 600;
		font-family: Avenir Next;
		text-align: center;
		margin: 0px;
	}
	h4 {
		font-size: 20px;
		font-weight: 400;
		font-family: Avenir Next;
		text-align: center;
	}
}

.mt-3 {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
	min-height: 55px;
	min-width: 450px;
	span {
		font-weight: 600;
		font-size: 18px;
		text-align: center;
	}
}

.mt-3.primary-btn-style {
	background-color: #202B5B;
	border-radius: 2px;
}

.mt-3.secondary-btn-style {
	background-color: #ffffff;
	border-radius: 2px;
	border-color: #202B5B;
	border-width: 2px;
	span {
		color: #202B5B;
	}
}