@import "../../styles/elements";

$card-container-margin: 5px;
$border-radius: 4px;
$card-image-height: 100px;
$card-image-width: 170px;
$overlay-opacity: 0.8;

.service-container {
	flex: 1;
	position: relative; 
	display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.service-item {
	flex: 1;
	position: relative; 
	display: flex;
    justify-content: center;
    align-items: center;
	border: 2.5px solid;
	border-radius: $border-radius;
}


.image-container {
    border-radius: $border-radius;
}

.service-image {
    height: $card-image-height;
    width: $card-image-width;
    overflow: hidden;
    border-radius: 2px;
    align-items: center;
    justify-content: center;
}

.service-title {
    font-family: $primary-font-family;
    font-size: 14px;
    font-weight: 600;
    color: $color-white;
    text-align: center;
	position: absolute;
}

.service-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $primary-color;
    border-radius: 2px;
    opacity: $overlay-opacity;
}
