@import "../../styles/elements";

.payment-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    position: relative;
    min-height: 100vh;
    padding-bottom: 60px;
    overflow: hidden;
  
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('../../assets/images/background.png') no-repeat center center fixed;
      background-size: cover;
      opacity: 0.2; 
      z-index: -1; 
    }

    .header {
        display: flex;
        margin-bottom: 20px;

        .logo {
            width: 50px;
            margin-right: 0px;
        }
    }

    .progress-indicator {
        width: 100%;
    }

    .funding-options {
        padding: 20px;
        width: 100%;
        background-color: white;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        margin-bottom: 30px;
      
        .funding-title {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 10px;
        }
      }      
}