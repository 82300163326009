@import "../../styles/elements";

.appointment-details {
    background-color: white;
    width: 100%;

    .appointment {
        background-color: white;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        margin-bottom: 25px;
        background-color: #fff;

        h3 {
            padding: 10px 20px 0px 20px;
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 500;
        }

        .appointment-time {
            display: flex;
            align-items: center;
            padding: 5px 20px 5px 20px;
            margin-bottom: 5px;
            font-size: 14px;

            .icon {
                margin-right: 5px;
                margin-top: -5px;
            }
        }

        .divider {
            border-top: 1px solid #e0e0e0;
        }

        .appointment-price {
            padding: 20px;
            font-size: 18px;
            font-weight: 500;
            display: flex;
            justify-content: space-between;
      
            .price-label {
              margin-right: 5px;
            }
          }
      
    }

    .total-price {
        padding: 20px;
        font-size: 18px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid #e0e0e0;
    }
}
