.header {
  z-index: 9999;
}
.header.floating-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.header .header__inner {
  background-color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  color: #9D377D;
  padding: 0 16px;
  height: 60px;
  border-bottom: 1px solid #D5D6D2;
  font-family: Avenir Next;
  align-items: center;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
}
.header .header__inner .header__title {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  color: inherit;
  text-transform: capitalize;
}
.header .header__inner .backlink .icon {
  font-size: 32px;
}
.header .header__inner .ant-row-flex .ant-col:first-child > :first-child {
  margin-right: 10px;
}
.header a {
  color: currentColor;
}
.header .icon {
  fill: currentColor;
}
.header .icon-menu {
  font-size: 48px;
  margin-right: 6px;
}
.header .icon-bell {
  font-size: 19px;
}
.header .icon-new-order {
  font-size: 16px;
}
.header .toolbar-button {
  background: transparent;
  border: none;
}
.header .toolbar-button:hover {
  background: transparent;
  border: none;
}
.header .logo {
  width: 40px;
  margin-right: 20px;
}
.header .input-container {
  display: flex;
  align-items: center;
}
.header .input-container input {
  border: 1px solid #D5D6D2;
  border-radius: 4px;
  height: 40px;
  padding: 8px;
  font-size: 14px;
  width: 100%;
  padding-left: 40px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}
.header .custom-input {
  width: 100%;
  color: #202B5B;
  font-family: Avenir Next;
}
.header .custom-input::placeholder {
  color: #D5D6D2;
}
.header .change-location-link {
  cursor: pointer;
  font-size: 16px;
  text-decoration: underline;
  margin-left: 20px;
}
@media screen and (max-width: 65rem) {
  .header .change-location-link {
    display: none;
  }
}
.header .location-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .location-container span {
  color: #202B5B;
  font-size: 18px;
}
.header .location-icon {
  width: 16px;
  margin-right: 5px;
}
.header .basket-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .basket-container span {
  font-size: 18px;
  font-weight: 600;
}
.header .search-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 10px;
}
.header .basket-icon {
  width: 22px;
  margin-right: 5px;
}
@media screen and (max-width: 85rem) {
  .header .basket-title {
    display: none;
  }
}

