@import "../../styles/elements";

.dashboard {
    position: relative;
    z-index: 1;
}

.content-container {
    margin-top: 60px;
    min-height: 100vh;
}

.nav-container {
    float: left;
    padding-right: 30px;
    border-right: 1px solid $color-welbeck-grey;
    background-color: white;
    position: fixed;
    left: 0;
    height: 100vh;
}

.nav-list {
    flex: 1;
    list-style: none;
    padding: 0;
}


.nav-list li {
    display: flex;
    align-items: center;
    margin: 20px;
    font-size: 20px;
    justify-content: center;
    align-items: center;
}

.nav-list li svg {
    margin-right: 10px;
}

.nav-list li a {
    color: $color-welbeck-dark-grey; 
    font-family: $primary-font-family;
    text-align: left;
    flex: 1;
}

.main-content {
    flex: 1;
    padding-left: 100px;
}