.main-content {
  margin: 0 auto;
}

.bg-image {
  position: absolute;
  top: 0
}


.bg-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 800px;
  z-index: -99;
  overflow: hidden;
}

.background-image {
  width: 100%;
  height: 800px;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.35;
}