@import "../../styles/elements";


.availability-container {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 10px;
    border-radius: 2px;
    background-color: $color-welbeck-purple;
    box-shadow: 0px 0px 5px 0px black;
}

.availability-text {
    font-family: $primary-font-family;
    font-size: 16px;
    color: $color-white;
}

.availability-compact-container {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 6px;
    border-radius: 2px;
    background-color: $color-welbeck-purple;
    box-shadow: 0px 0px 5px 0px black;
}
