.radio-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
  
    &.active {
      .radio-label {
        color: #8b008b;
      }
    }
  
    &.inactive {
      .radio-label {
        color: #000;
      }
    }
  
    &:hover .radio-label {
      color: #8b008b;
    }
  
    svg {
      margin-right: 10px;
    }
  
    .radio-label {
      font-size: 16px;
    }
  }
  