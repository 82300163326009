@import "../../styles/elements";

.appointment-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    position: relative;
    min-height: 100vh;
    padding-bottom: 60px;
    overflow: hidden;
  
    // Background image with opacity using pseudo-element
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('../../assets/images/background.png') no-repeat center center fixed;
      background-size: cover;
      opacity: 0.2; // Adjust the opacity as needed
      z-index: -1; // Ensure the pseudo-element is behind other content
    }

    .header {
        display: flex;
        margin-bottom: 20px;

        .logo {
            width: 50px;
            margin-right: 0px;
        }
    }

    .progress-indicator {
        width: 100%;
    }

    .appointments {
        width: 100%;
        overflow: hidden;
        max-width: 600px;
        flex-grow: 1; 
        margin-bottom: 20px;

        h1 {
            font-size: 24px;
            color: #202B5B;
            margin-top: 10px;
            margin-left: 10px;
        }
        h3 {
            font-size: 18px;
            color: #202B5B;
            margin-top: 5px;
        }

        p {
            font-size: 16px;
            color: #202B5B;
            margin-left: 10px;
        }
    }
    .accordion-title {
        font-size: 18px;
        color: #202B5B;

        .completed-appointment {
          .completed-appointment-date {
              display: flex;
              align-items: center;

              .icon {
                  margin-right: 5px;
              }
            }
          }
    }

    .submitted-documents {
      margin-top: 10px;

      .document-item {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .document-icon {
              margin-right: 10px;
          }
      }
  }

    .accordion-content {
        font-size: 16px;
        color: #202B5B;
        padding: 0 7px;
        padding-bottom: 1px;

        .next-appointment {
            margin-left: 0px;
        }
        .appointment-info {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-bottom: 10px;
      
            .appointment-date,
            .appointment-time {
              display: flex;
              align-items: center;
              margin-bottom: 10px;
      
              .icon {
                margin-right: 5px;
              }
            }
      }
    
        .button-container {
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-bottom: 10px;
    
          .book-appointment-button {
            width: 100%;
            border-radius: 0;
            background-color: #202B5B;
            color: white !important;
            font-size: 18px;
            padding: 20px 10px;
            padding-bottom: 45px;
          
            &:hover,
            &:focus {
              color: white !important;
              border-color: #202B5B !important;
              background-color: #202B5B !important;
              box-shadow: none !important;
            }
          }

          .see-availability-button {
            border: 1px solid #202B5B;
            font-size: 18px;
            padding: 20px 10px;
            padding-bottom: 45px;
            border-radius: 0;
            background-color: white !important;
            color: #202B5B !important;

            &:hover,
            &:focus {
              color:  #202B5B !important;
              border-color: #202B5B !important;
              background-color: white !important;
              box-shadow: none !important;
            }
          }
        }
      }

      .complete-booking-button {
        width: 100%;
        max-width: 580px;
        font-size: 18px;
        border-radius: 0;
        padding: 20px 10px;
        padding-bottom: 45px;
        background-color: #202B5B;
        color: white;

        &.enabled {
            cursor: pointer;
        }

        @media (max-width: 600px) {
            width: 100%;
            max-width: 350px;
            border-radius: 0;
            font-size: 18px;
            padding: 20px 10px;
            padding-bottom: 45px;
        }

        &:hover,
        &:focus {
            color: white !important;
            border-color: #202B5B !important;
            background-color: #202B5B !important;
            box-shadow: none !important;
        }
    }

    .complete-booking-button:disabled {
        background-color: #d5d6d2;
        color: #a0a0a0;
        cursor: not-allowed;
    }

}