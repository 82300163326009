.separator-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 12px 0;
}

.separator {
    height: 24px;
    width: 100px;
}

.full-width {
    width: 100%;
    flex: 1;
}

.separator-stroke {
    height: 11px;
    border-bottom: 1px solid #ccc;
}

.separator-text {
    font-family: Avenir Next;
    font-size: 13px;
    color: #ccc;
    padding: 0 8px;
}
