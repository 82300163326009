@import "../../styles/elements";

.confirm-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: url('../../assets/images/background.png') no-repeat center center fixed;
  background-size: cover;
  position: relative;
  min-height: 100vh;
  padding-bottom: 60px;

  .header {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .logo {
      width: 50px;
      margin-right: 0px;
    }
  }

  .result-container {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    align-items: center;
    background: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;

    .result-icon {
      font-size: 100px;
      margin-bottom: 20px;
      color: #9D377D;
    }

    .result-title {
      font-size: 24px;
      margin-bottom: 20px;
      line-height: 23px;
    }

    .result-message {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
}
